// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-portfolio-filter-js": () => import("./../../src/pages/portfolio/filter.js" /* webpackChunkName: "component---src-pages-portfolio-filter-js" */),
  "component---src-pages-portfolio-hero-js": () => import("./../../src/pages/portfolio/hero.js" /* webpackChunkName: "component---src-pages-portfolio-hero-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-tags-js": () => import("./../../src/pages/portfolio/tags.js" /* webpackChunkName: "component---src-pages-portfolio-tags-js" */),
  "component---src-pages-tags-index-js": () => import("./../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-page-js": () => import("./../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-global-page-js": () => import("./../../src/templates/global-page.js" /* webpackChunkName: "component---src-templates-global-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-not-found-page-js": () => import("./../../src/templates/notFound-page.js" /* webpackChunkName: "component---src-templates-not-found-page-js" */),
  "component---src-templates-portfolio-post-js": () => import("./../../src/templates/portfolio-post.js" /* webpackChunkName: "component---src-templates-portfolio-post-js" */),
  "component---src-templates-portfolio-tags-js": () => import("./../../src/templates/portfolio-tags.js" /* webpackChunkName: "component---src-templates-portfolio-tags-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-services-page-js": () => import("./../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-terms-page-js": () => import("./../../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */)
}

